<template>
  <div class="link-account">
    <div class="tw-container">
      <!-- 使用者資訊 -->
      <div class="user-info">
        <p class="fw-bold mb-2 tw-text-size18 tw-border-start">
          使用者名稱: {{ userInfo.name }}
        </p>
        <p class="fw-bold mb-2 tw-text-size18 tw-border-start">
          使用者帳號: {{ userInfo.account }}
        </p>
        <!-- 按鈕操作區塊 -->
        <div>
          <button
            class="tw-btn tw-btn-danger me-2"
            v-if="!userInfo.verify && currentPage === 'S_LinkAccounts'"
            @click="showModal('verifyEmail')"
          >
            團媽尚未驗證信箱
          </button>
          <button
            class="tw-btn tw-btn-success me-2"
            @click="showModal('resetPassword')"
          >
            變更密碼
          </button>
          <p
            class="mt-3 me-2"
            v-if="currentPage === 'S_LinkAccounts' && show30DaysFreeText"
          >
            <router-link to="/user/storeLists" class="tw-btn tw-btn-gold"
              >立即前往領取一個月試用期 ^^</router-link
            >
          </p>
        </div>
      </div>
      <hr />
      <!-- 關聯帳號 -->
      <div>
        <!-- 上方 -->
        <div class="mb-3">
          <p class="fw-bold tw-text-size18">關聯帳號</p>
        </div>
        <!-- 下方 -->
        <div class="linkAccounts-area">
          <!-- 關聯 FB -->
          <div class="link-box fb-box">
            <img
              class="info-icon"
              src="@/assets/icon/interrogation.png"
              alt=""
              @click="showModal('teaching', 'fb')"
            />
            <img
              class="social-icon-img"
              src="@/assets/icon/facebook-3.png"
              alt=""
            />
            <div class="divider"></div>
            <p class="text-center fw-bold mb-4">
              <span class="text-light" v-if="userFacebookLink.is_link">
                {{ userFacebookLink.name }}
              </span>
              <span class="text-danger" v-else>未關聯</span>
            </p>
            <div>
              <button
                class="tw-btn tw-btn-light text-primary"
                v-if="!userFacebookLink.is_link"
                @click="linkFb"
              >
                立即關聯
              </button>
              <button
                class="tw-btn tw-btn-light text-danger"
                v-else
                @click="unlinkFb"
              >
                取消關聯
              </button>
            </div>
          </div>
          <!-- 關聯 Line -->
          <div class="link-box line-box">
            <img
              class="info-icon"
              src="@/assets/icon/interrogation.png"
              alt=""
              @click="showModal('teaching', 'line')"
            />
            <img class="social-icon-img" src="@/assets/icon/line.png" alt="" />
            <div class="divider"></div>
            <p class="text-center fw-bold mb-4">
              <span class="text-light" v-if="userLineLink.is_link">
                {{ userLineLink.name }}
              </span>
              <span class="text-danger" v-else>未關聯</span>
            </p>
            <div>
              <button
                class="tw-btn tw-btn-light text-primary"
                v-if="!userLineLink.is_link"
                @click="linkLine"
              >
                立即關聯
              </button>
              <button
                class="tw-btn tw-btn-light text-danger"
                v-else
                @click="unlinkLine"
              >
                取消關聯
              </button>
            </div>
          </div>
          <!-- 關聯 Notify -->
          <div class="link-box notify-box">
            <img
              class="info-icon"
              src="@/assets/icon/interrogation.png"
              alt=""
              @click="showModal('teaching', 'notify')"
            />
            <img
              class="social-icon-img"
              src="@/assets/icon/linenotify.png"
              alt=""
            />
            <div class="divider"></div>
            <div v-if="userLineLink.is_link">
              <p class="text-center fw-bold mb-4">
                <span class="text-light" v-if="userNotifyLink.is_link">
                  一對一Notify
                </span>
                <span class="text-danger" v-else>未關聯</span>
              </p>
              <div class="text-center">
                <button
                  class="tw-btn tw-btn-light text-primary"
                  v-if="!userNotifyLink.is_link"
                  @click="linkNotify"
                >
                  立即關聯
                </button>
                <button
                  class="tw-btn tw-btn-light text-danger"
                  v-else
                  @click="unlinkNotify"
                >
                  取消關聯
                </button>
              </div>
            </div>
            <p class="text-light fw-bolder" v-else>請先綁定 Line</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal -->
  <!-- 信箱驗證的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="verifyEmailModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">信箱驗證</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-info" role="alert">
            發送後請至申辦此帳號的信箱做驗證 !
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="resendVerifyEmail"
          >
            發送
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 變更密碼的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="resetPasswordModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">變更密碼</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 舊密碼 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="OldPassword"
              placeholder="舊密碼(40字)"
              maxlength="40"
              v-model="resetPasswordData.oldPassword"
            />
            <label for="OldPassword">舊密碼(40字)</label>
          </div>
          <!-- 新密碼 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="NewPassword"
              placeholder="新密碼(40字)"
              maxlength="40"
              v-model="resetPasswordData.newPassword"
            />
            <label for="NewPassword">新密碼(40字)</label>
          </div>
          <!-- 確認密碼 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="ConfirmPassword"
              placeholder="密碼確認(40字)"
              maxlength="40"
              v-model="resetPasswordData.confirmPassword"
            />
            <label for="ConfirmPassword">密碼確認(40字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="resetPassword"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 教學圖的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="teachingModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">教學圖
            <a href="https://youtu.be/ppW8eE96rOc?si=suQ0Z2L9x6wXa5mB" target="_blank">
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
              />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img
            src="@/assets/images/teaching-images/user-fb-link.png"
            alt="fb教學圖"
            v-if="teachingMode === 'fb'"
            style="width: 100%"
          />
          <img
            src="@/assets/images/teaching-images/user-line-link.png"
            alt="line教學圖"
            v-else-if="teachingMode === 'line'"
            style="width: 100%"
          />
          <img
            src="@/assets/images/teaching-images/user-notify-link.png"
            alt="notify教學圖"
            v-else-if="teachingMode === 'notify'"
            style="width: 100%"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            了解
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// api
import { parseToken, sortoutParseTokenResult } from '@/methods/API/parseToken'
import { authFb } from '@/methods/API/fbApi'

export default {
  data() {
    return {
      // *modal
      verifyEmailModal: {},
      resetPasswordModal: {},
      teachingModal: {},
      // *data
      serverToken: '',
      currentPage: '',
      // 使用者資訊
      userInfo: {},
      userFacebookLink: {
        is_link: false,
        name: ''
      },
      userLineLink: {
        is_link: false,
        name: ''
      },
      userNotifyLink: {
        is_link: false,
        name: ''
      },
      show30DaysFreeText: false,
      // 關聯 line
      scopes: "profile%20openid%20email",
      // 修改密碼資料
      resetPasswordData: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      // 合併帳號資料
      mergePhysicalUserData: {
        account: '',
        password: ''
      },
      teachingMode: 'fb'
    }
  },
  created() {
    this.serverToken = this.$methods.getCookie('serverToken')
    this.currentPage = this.$route.name
    this.getUserInfo()
  },
  mounted() {
    // 生成 bootstrap modal
    this.createModals([
      'verifyEmailModal', 'resetPasswordModal', 'teachingModal'
    ])
  },
  methods: {
    // 開啟 modal
    showModal(status, item) {
      if (status === 'verifyEmail') {
        // 信箱驗證
        this.verifyEmailModal.show()
      } else if (status === 'resetPassword') {
        // 變更密碼
        this.resetPasswordData = {
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        }
        this.resetPasswordModal.show()
      } else if (status === 'teaching') {
        // 教學圖
        this.teachingMode = item
        this.teachingModal.show()
      }
    },
    // 取得使用者資訊
    getUserInfo() {
      this.$methods.switchLoading('show')
      const result = parseToken(this.serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.getPhysicalUserInfo(result)
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得實體使用者資訊
    getPhysicalUserInfo(userInfo) {
      const vm = this
      const getPhysicalUserInfo = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 1,
          ids: userInfo.ids,
          methods: '{getUserLineLink,getUserFacebookLink,getPaymentRecords}'
        }
      ]
      // 初始化資料
      this.userFacebookLink = {
        is_link: false,
        name: ''
      }
      this.userLineLink = {
        is_link: false,
        name: ''
      }
      this.userNotifyLink = {
        is_link: false,
        name: ''
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserInfo,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const physicalUserInfo = res.data[0].objects[0]
            vm.userInfo = physicalUserInfo
            // 檢查是否綁定 FB
            if (physicalUserInfo.userFacebookLink && physicalUserInfo.userFacebookLink.appUserId) {
              vm.userFacebookLink = {
                is_link: true,
                name: physicalUserInfo.userFacebookLink.name
              }
            }
            // 檢查使否綁定 Line
            if (physicalUserInfo.userLineLink && physicalUserInfo.userLineLink.appUserId) {
              vm.userLineLink = {
                is_link: true,
                name: physicalUserInfo.userLineLink.name
              }
            }
            // 檢查是否綁定 Notify
            if (physicalUserInfo.userLineLink.notifyAccessToken) {
              vm.userNotifyLink = {
                is_link: true,
                name: physicalUserInfo.userLineLink.name
              }
            }
            // 檢查是否尚未開通 30 天試用期
            if (physicalUserInfo.paymentRecords.length === 0) vm.show30DaysFreeText = true
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert('other', err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 信箱驗證
    resendVerifyEmail() {
      this.$methods.switchLoading('show')
      const vm = this
      const verifyEmailApi = `${process.env.VUE_APP_API}/user/resendVerifyEmail`
      const header = {
        authorization: this.serverToken
      }
      $.ajax({
        type: "POST",
        async: true,
        headers: header,
        url: verifyEmailApi,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.verifyEmailModal.hide()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function (err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 變更密碼
    resetPassword() {
      this.$methods.switchLoading('show')
      const vm = this
      const resetPasswordAPI = `${process.env.VUE_APP_API}/user/resetPassword`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      // 檢查是否都有填寫
      let errorString = ''
      if (!this.resetPasswordData.oldPassword) {
        errorString += '舊密碼 '
      }
      if (!this.resetPasswordData.newPassword) {
        errorString += '新密碼 '
      }
      if (!this.resetPasswordData.confirmPassword) {
        errorString += '密碼確認 '
      }
      if (errorString) {
        this.SweetAlert('other', `${errorString} 尚未輸入`)
        return this.$methods.switchLoading('hide')
      }
      data.append('oldPassword', this.resetPasswordData.oldPassword)
      data.append('newPassword', this.resetPasswordData.newPassword)
      data.append('confirmPassword', this.resetPasswordData.confirmPassword)
      $.ajax({
        type: 'PUT',
        async: true,
        url: resetPasswordAPI,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.resetPasswordModal.hide()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 關聯 FB
    linkFb() {
      // 登入 FB
      authFb("authorization")
    },
    // 取消 FB 關聯
    unlinkFb() {
      this.$methods.switchLoading('show')
      const vm = this
      const unlinkFbApi = `${process.env.VUE_APP_API}/apps/fb/unlink`
      const header = {
        authorization: this.serverToken
      }
      $.ajax({
        type: 'PUT',
        async: true,
        url: unlinkFbApi,
        headers: header,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getUserInfo()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert('other', err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 綁定 Line
    linkLine() {
      const loginRequestAuthUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.VUE_APP_LINELOGIN_ID}&redirect_uri=${process.env.VUE_APP_LINELOGIN_CALLBACK}&scope=${this.scopes}&state=${this.serverToken}&bot_prompt=aggressive`;
      window.location = loginRequestAuthUrl;
    },
    // 取消 Line 綁定
    unlinkLine() {
      this.$methods.switchLoading('show')
      const vm = this
      const unlinkLineApi = `${process.env.VUE_APP_API}/apps/line/unlink`
      const header = {
        authorization: this.serverToken
      }
      $.ajax({
        type: 'PUT',
        async: true,
        url: unlinkLineApi,
        headers: header,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getUserInfo()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert('other', err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 綁定 Notify
    linkNotify() {
      const notifyRequestAuthUrl = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${process.env.VUE_APP_NOTIFY_ID}&redirect_uri=${process.env.VUE_APP_NOTIFY_CALLBACK}&scope=notify&state=${this.serverToken}`;
      window.location = notifyRequestAuthUrl;
    },
    // 取消 Notify 綁定
    unlinkNotify() {
      this.$methods.switchLoading('show')
      const vm = this
      const unlinkNotifyApi = `${process.env.VUE_APP_API}/apps/line/revokeNotify`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('revokeUserNotify', true)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: unlinkNotifyApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getUserInfo()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert('other', err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
  }
}
</script>